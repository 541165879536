import z from 'zod'

export default {
  required(v, k) {
    const validArray = Array.isArray(v) && v.length > 0
    const validNonArray = !Array.isArray(v) && v != null && v !== ''
    const error = k === undefined ? 'This field is required' : `${k} is required`
    return validArray || validNonArray || error
  },
  customError(value, error) {
    return (value != null && value !== '') || (error === undefined ? 'Error' : error)
  },
  alpha(value, key) {
    try {
      const schema = z.string().regex(/^[a-zA-Z]+$/)
      schema.parse(value)
      return true
    } catch (error) {
      return key === undefined ? 'Can only contain letters' : `${key} can only contain letters`
    }
  },
  alphaNumeric(value, key) {
    try {
      const schema = z.string().regex(/^[a-zA-Z0-9]+$/)
      schema.parse(value)
      return true
    } catch (error) {
      return key === undefined
        ? 'Can only contain letters and numbers'
        : `${key} can only contain letters and numbers`
    }
  },
  alphaNumericHyphen(message = 'Can only contain letters, numbers and hyphen. eg "abc-123"') {
    return (value, key) => {
      try {
        const schema = z.string().regex(/^[a-zA-Z0-9]+-[a-zA-Z0-9]+$/)
        schema.parse(value)
        return true
      } catch (error) {
        return key === undefined ? message : `${key} ${message}`
      }
    }
  },
  alphaNumericMuliHyphens(message = 'Can only contain letters, numbers and hyphens. eg "abc-123"') {
    return (value, key) => {
      try {
        const schema = z.string().regex(/^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/)
        schema.parse(value)
        return true
      } catch (error) {
        return key === undefined ? message : `${key} ${message}`
      }
    }
  },
  name(v, k) {
    try {
      const schema = z.string().regex(/^[a-zA-Z ]+$/)
      schema.parse(v)
      return true
    } catch (error) {
      return k === undefined
        ? 'Can only contain letters and spaces'
        : `${k} can only contain letters and spaces`
    }
  },
  phone(value, key) {
    try {
      const phoneSchema = z.string().refine(
        (value) => {
          const phoneRegex = /^(?:\+?\d{1,3})?(?:[ -]?\(?\d{1,4}\)?[ -]?)?\d{6,14}$/
          return phoneRegex.test(value)
        },
        {
          message: 'Please enter valid mobile number',
        }
      )
      phoneSchema.parse(value)
      return true
    } catch (error) {
      return key === undefined ? 'Invalid phone number format' : `${key} is invalid`
    }
  },
  phoneStartWithPlus(value, key) {
    try {
      const phoneSchema = z.string().refine((value) => {
        const phoneRegex = /^\+\d{10,14}$/
        return phoneRegex.test(value)
      })
      phoneSchema.parse(value)
      return true
    } catch (error) {
      return key === undefined
        ? 'Invalid phone number format, Please include country code, E.g., +61412345678'
        : `${key} is invalid,include country code, E.g., +61412345678`
    }
  },
  lowerCase(value, key) {
    try {
      const noUppercaseRegex = /^[^A-Z]*$/
      const schema = z.string().regex(noUppercaseRegex)
      schema.parse(value)
      return true
    } catch (error) {
      return key === undefined
        ? 'Can only contain lowercase letters'
        : `${key} can only contain lowercase letters`
    }
  },
  minMaxString(min, max) {
    return (value, key) => {
      const schema = z.string().min(min).max(max)
      try {
        schema.parse(value)
        return true
      } catch (error) {
        return key === undefined
          ? `Must be between ${min} and ${max} characters`
          : `${key} must be between ${min} and ${max} characters`
      }
    }
  },
  /**
   * This is required because the vuetify rules prop is run before the onBlur event
   * Later on the non ascii characters are removed before formatting the phone number
   * See method ParentCarer.updateAndFormatContactValue()
   *
   * \x00-\x1f = non ascii characters (char code 0-31 in hex)
   * \x7f-\uffff = non ascii characters (char code 127-65535 in hex)
   * x30-\x39 = ascii characters (digit 0-9)
   * x20\ = ascii characters (space)
   * x28\x29 = ascii characters (left and right bracket)
   * x2b\x2d = ascii characters (+ and -)
   * @param {*} v
   * @returns
   */
  containsNonAsciiOrValidCharactersForPhoneNumber(v) {
    return (
      !v ||
      // eslint-disable-next-line no-control-regex
      /^[\x00-\x1f\x7f-\uffff\x30-\x39\x20\x28\x29\x2b\x2d]+$/.test(v) ||
      'Can only contain 0-9, spaces and - ( ) +'
    )
  },
  email(value, key) {
    try {
      const schema = z.string().email()
      schema.parse(value)
      return true
    } catch (error) {
      return key === undefined ? 'Invalid email' : `Please enter a valid ${key}`
    }
  },
  emailDomainAllowed(validDomains) {
    return (value) => {
      const domain = (value || '@').split('@')[1]
      return validDomains.includes(domain) || `Only ${validDomains.join(', ')} domains are allowed`
    }
  },
  isValidURL(value) {
    try {
      const schema = z.string().url()
      schema.parse(value)
      return true
    } catch (error) {
      return 'Invalid URL, E.g., https://example.com'
    }
  },
  emptyArray(value, error) {
    return (value != null && value.length !== 0) || (error === undefined ? 'Error' : error)
  },
  maxItems(v, k) {
    const validArray = Array.isArray(v) && v.length <= 5
    const validNonArray = !Array.isArray(v) && v != null && v.split(',').length <= 5
    const error =
      k === undefined ? "Can't have more than 5 items" : `${k} can't have more than 5 items`

    return validArray || validNonArray || error
  },
}
